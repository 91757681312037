import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
export default observer(function ResponsiveHeaderPage() {      
  const { commonStore,modalStore,userStore,wizardStore,companyStore} = useStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (commonStore.token) {      
     userStore.getUser().finally(() => commonStore.setAppLoaded(true))		 
    } else {
      commonStore.setAppLoaded(true);
    }
  }, [])
    return (        
        <section data-bs-version="5.1" className="menu menu01 markm5 cid-ub15rYObCi" id="menu01-3">
        <nav className="navbar navbar-dropdown navbar-expand-lg">
            <div className="menu_box container-fluid">
                <div className="navbar-brand d-flex">
                    <span className="navbar-logo">
                        <a href="https://www.jmdenterprises.net.in/">
                            <img src="/assets/images/jmdenterpriseslogo-100x100.webp" alt="JMD Enterprises" />
                        </a>
                    </span>
                    <span className="navbar-caption-wrap"><a className="navbar-caption text-info display-5" href="https://www.jmdenterprises.net.in/">&nbsp;</a></span>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <div className="hamburger">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                </div>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true">
                        <li className="nav-item">
                            <a className="nav-link link text-success display-4" style={{cursor:'pointer'}} onClick={()=>{
                        navigate("/");
                    }}>
                                Home
                            </a>
                        </li>                        
                        <li className="nav-item">
                            <a className="nav-link link text-success display-4" style={{cursor:'pointer'}} onClick={()=>{                                                
                            navigate("/aboutus");
                        }}>About Us</a>
                        </li>                        
                        <li className="nav-item">
                        <a className="nav-link link text-success display-4" style={{cursor:'pointer'}} onClick={()=>{
                            navigate("/terms");
                            }}>Terms Conditions</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link link text-success display-4" style={{cursor:'pointer'}} onClick={()=>{
                            navigate("/privacy");
                            }}>Privacy Policy</a>
                            </li>                            
                            <li className="nav-item">
                                <a className="nav-link link text-success display-4" style={{cursor:'pointer'}} 
                                onClick={()=>{
                                    navigate("/refund");
                                }}>Refund Policy</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link link text-success display-4" style={{cursor:'pointer'}} 
                                onClick={()=>{
                                    navigate("/contactus");
                                }}>Contact Us</a>
                            </li>
                        </ul>
                </div>
                <div className="offcanvas_box">
                    <button className="btn_offcanvas" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                        <div className="hamburger">
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                    <div className="offcanvas offcanvas-end" style={{tabindex:-1}} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                        <div className="offcanvas-header">
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            
                            <h3 className="mbr-section-subtitle mbr-fonts-style display-2">JMD Enterprises</h3>
                            <div className="offcanvas_contact">
                                <p className="mbr-text mbr-fonts-style display-7">We are committed to providing the finest quality rice and pulses to our customers worldwide. With a focus on excellence, integrity, and customer satisfaction, we have established ourselves as a leading manufacturer and wholesaler in the industry.
                                </p>
                                <p className="text_widget mbr-fonts-style display-4">
                                    <a href="mailto:jmdenterprises3353@gmail.com" className="text-success">jmdenterprises3353@gmail.com</a>
                                    <br/><br/>
                                    <a href="tel:919534073353" className="text-success">+919534073353</a>
                                </p>
                            </div>                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </section>        
    )
})