import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage"
const firebaseConfig = {
  apiKey: "AIzaSyCeOSCfKnM5Cv84SHbrVV6txVVHnpc-NEc",
  authDomain: "jmdenterprises-38042.firebaseapp.com",
  projectId: "jmdenterprises-38042",
  storageBucket: "jmdenterprises-38042.appspot.com",
  messagingSenderId: "129000318930",
  appId: "1:129000318930:web:0b5f86b12f0d4dda130a0b"
};
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);