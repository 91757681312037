import { observer } from 'mobx-react-lite';
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Grid,
    Header,
    Icon,
    Image,
    Menu,
    Segment
  } from "semantic-ui-react";
  import "./WebHomePage.css";  
import { useStore } from '../../app/stores/store';
export default observer(function TermsConditionsPage() {
    const { commonStore,modalStore } = useStore();
    const currentyear = new Date().getFullYear();
    const randomNumberInRange = (min:number, max:number) => { 
        return Math.floor(Math.random()  
                * (max - min + 1)) + min; 
    };
    return (
        <section data-bs-version="5.1" className="buildm5 features3 cid-ub1ti4KCpb" id="features3-12">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 content-head">
        <div className="card-wrapper mb-5">
          <div className="card-box align-center">
            <h4 className="mbr-section-title mbr-fonts-style mb-0 display-5">Terms and Conditions</h4>
            <div className="line"></div>
            <p className="mbr-text mbr-fonts-style mt-4 pb-4 display-7">
              These terms and conditions outline the rules and regulations for the use of our website and services</p>


          </div>
        </div>
      </div>
    </div>
    <div className="row content-row">
      <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb active">
        <div className="item-wrapper">
          <div className="title mb-3">
            <span className="num mbr-fonts-style display-7">01</span>
          </div>
          <h4 className="card-title mbr-fonts-style pb-2 display-5">Agreement to Terms</h4>
          <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">By accessing this website and using our services, you agree to abide by these terms and conditions in full. If you disagree with any part of these terms and conditions, you must not use our website or services.<br/></p>
          <div className="line2"></div>
        </div>
      </div>
      <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
        <div className="item-wrapper">
          <div className="title mb-3">
            <span className="num mbr-fonts-style display-7">02</span>
          </div>
          <h4 className="card-title mbr-fonts-style pb-2 display-5">License to Use</h4>
          <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">Unless otherwise stated, JMD Enterprises and/or its licensors own the intellectual property rights for all material on this website. You may view, download, and print pages from the website for your own personal use, subject to the restrictions set out.<br/></p>
          <div className="line2"></div>
        </div>
      </div>
      <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
        <div className="item-wrapper">
          <div className="title mb-3">
            <span className="num mbr-fonts-style display-7">04</span>
          </div>
          <h4 className="card-title mbr-fonts-style pb-2 display-5">Changes to Terms</h4>
          <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">We reserve the right to modify or replace these terms and conditions at any time. Changes will be effective immediately upon posting on this website. It is your responsibility to review these terms periodically for updates.<br/></p>
          <div className="line2"></div>
        </div>
      </div><div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
        <div className="item-wrapper">
          <div className="title mb-3">
            <span className="num mbr-fonts-style display-7">03</span>
          </div>
          <h4 className="card-title mbr-fonts-style pb-2 display-5">Limitations of Liability</h4>
          <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">In no event shall JMD Enterprises be liable for any damages arising out of the use or inability to use our website or services, including but not limited to direct, indirect, incidental, special, or consequential damages. <br/></p>
          <div className="line2"></div>
        </div>
      </div>
    </div>
  </div>
</section>      
    )
})